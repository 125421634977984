.customImageOverlay {
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;

}

.imageOverlayButton {
    opacity: 0 !important;

}

.customImageOverlay:hover .imageOverlayButton {
    opacity: 1 !important;
    transition: all 0.4s;
}