.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  z-index: 1106;
  opacity: 1;
}
.preloader > * {
  min-width: 0;
}
.preloader__wrap {
  width: 100%;
  max-width: 9.375rem;
}
.preloader__progress {
  background: #ffffff45;
  height: 0.125rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
}
.preloader__progress span {
  background: #e12465;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 0;
}

/*Line Progress*/
.loading__overlay {
  position: fixed;
  display: block;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 500000 !important;
  background-color: #ffffff45;
}
.indeterminate {
  background-color: #e12465;
}
.indeterminate:before {
  z-index: -100 !important;
  height: 0.125rem;
  content: "";
  position: fixed;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.indeterminate:after {
  z-index: -100 !important;
  height: 3px;
  content: "";
  position: fixed;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}
@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}
