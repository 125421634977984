.customModal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    background: #000000d1;
    top: 0;
    display: none;

}

.activeModal {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden;
}

.customModalBody {
    width: 50%;
    background: #fff;
    padding: 4rem 3rem;
    border-radius: 1rem;
}