.ProfileName {
  color: #898383;
  padding: 10px;
  font-size: 24px;
  text-align: center;
  border-bottom: 2px solid;
  margin: 0px 0px 20px;
}

.collectorWrapper {
  padding: 0px 20px;
}

.profileImage {
  width: 100%;
  padding: 20px;
  text-align: center;
}
.profileImage > img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid;
}
