/* .react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: end;
}



.react-bootstrap-table.table-responsive table thead,
.react-bootstrap-table.table-responsive table tbody tr {
  table-layout: fixed;
  display: table;
  width: 100%;
} */

.react-bootstrap-table {
  height: 90vh !important;
  overflow-y: visible;
  display: block;

}





/* pdf css  */

@page {
  margin: 15mm;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
}

@page {
  size: auto;
  margin: 20mm;
}